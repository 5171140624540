import { formAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(formAnatomy.keys);

const variants = {
  defaultInput: definePartsStyle({
    container: {
      label: {
        color: 'black',
        fontWeight: 'bold',
        marginBottom: '5px'
      }
    }
  })
};

export const formTheme = defineMultiStyleConfig({
  variants,
  defaultProps: {
    variant: 'defaultInput'
  }
});
