import { Box, Flex, Text } from "@chakra-ui/react";
import { UseFormReturn } from "react-hook-form";
import { Lead } from "../../leads/models/lead.ts";
import { createFieldHelper } from "@cksoftware/react-base";
import { useState } from "react";
import { formGap } from "../LeadCapture.tsx";

type Props = {
  formMethods: UseFormReturn<Lead>;
};

export const PropertyLocation = (props: Props) => {
  const fieldHelper = createFieldHelper<Lead>(props.formMethods);
  const [isManualEntry, setIsManualEntry] = useState(false);

  const fields = [
    fieldHelper
      .booleanInput("UnderContract")
      .withLabel("Do you have this property under contract?")
      .withValidation({ required: {} }),
    fieldHelper
      .addressInput("AddressModel.AddressSearchString", {
        selectedAddressStringField: "AddressModel.AddressSearchString",
        cityField: "AddressModel.City",
        provinceField: "AddressModel.Province",
        postalCodeField: "AddressModel.PostalCode",
        streetField: "AddressModel.AddressLineOne",
      })
      .withLabel("Search Property Address")
      .withRenderUnder(() => (
        <Text fontSize={"sm"} fontStyle={"italic"} marginTop={"-10px"}>
          Can't find your address?{" "}
          <Box
            display={"inline"}
            textDecoration={"underline"}
            color={"tertiary.500"}
            cursor={"pointer"}
            onClick={() => setIsManualEntry(true)}
          >
            Click here
          </Box>{" "}
          for manual entry.
        </Text>
      )),

    fieldHelper
      .textInput("AddressModel.AddressLineOne")
      .withLabel("Address Line 1")
      .withValidation({ required: {} }),
    fieldHelper
      .textInput("AddressModel.AddressLineTwo")
      .withLabel("Address Line 2"),
    fieldHelper.textInput("AddressModel.UnitNumber").withLabel("Unit Number"),
    fieldHelper
      .textInput("AddressModel.City")
      .withLabel("City")
      .withValidation({ required: {} }),
    fieldHelper
      .textInput("AddressModel.PostalCode")
      .withLabel("Postal Code")
      .withValidation({ required: {} }),
    fieldHelper
      .textInput("AddressModel.Province")
      .withLabel("Province")
      .withValidation({ required: {} }),
    fieldHelper
      .textInput("LandOwnerInformation")
      .withLabel(
        "Who owns the land/represents the land and who are they to you?",
      )
      .withValidation({ required: {} }),
    fieldHelper.textInput("Neighborhood").withValidation({ required: {} }),
  ];
  return (
    <Flex flexDir={"column"} gap={formGap}>
      {fieldHelper.renderFieldHelperInput("UnderContract", fields)}
      {isManualEntry && [
        fieldHelper.renderFieldHelperInput("AddressModel.UnitNumber", fields),
        fieldHelper.renderFieldHelperInput(
          "AddressModel.AddressLineOne",
          fields,
        ),
        fieldHelper.renderFieldHelperInput(
          "AddressModel.AddressLineTwo",
          fields,
        ),
        fieldHelper.renderFieldHelperInput("AddressModel.City", fields),
        fieldHelper.renderFieldHelperInput("AddressModel.Province", fields),
        fieldHelper.renderFieldHelperInput("AddressModel.PostalCode", fields),
      ]}
      {!isManualEntry &&
        fieldHelper.renderFieldHelperInput(
          "AddressModel.AddressSearchString",
          fields,
        )}
      {fieldHelper.renderFieldHelperInput("LandOwnerInformation", fields)}
      {fieldHelper.renderFieldHelperInput("Neighborhood", fields)}
    </Flex>
  );
};
