import { defineStyle, defineStyleConfig } from '@chakra-ui/react';

const smallDisplay = defineStyle({
  color: 'black',
  fontWeight: 'medium',
  fontSize: 'small',
  display: 'inline',
});

const smallLabel = defineStyle({
  color: 'black',
  fontWeight: 'bold',
  fontSize: 'small',
  display: 'inline',
});

export const textTheme = defineStyleConfig({
  variants: { smallDisplay, smallLabel },
});
