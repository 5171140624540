import { Box, BoxProps } from "@chakra-ui/react";
import { Outlet } from "react-router-dom";
import { Nav } from "../../features/header/Nav";

type NarrowLayoutProps = {
  containerProps?: BoxProps;
};

export const NarrowLayout = (props: NarrowLayoutProps) => {
  return (
    <Box as="section" width="100vw" height="100vh" overflowY="auto">
      <Nav />
      <Box
        w={["100%", "100%", "100%", "90%"]}
        margin={"auto"}
        paddingLeft={"5px"}
        paddingRight={"5px"}
        paddingTop={"10px"}
        {...props.containerProps}
      >
        <Outlet />
      </Box>
    </Box>
  );
};
