import { defineStyle, defineStyleConfig } from '@chakra-ui/react';

const secondary = defineStyle({
  backgroundColor: 'secondary.500',
  color: 'white',
  borderRadius: '8px',
  width: ['100px', '200px'],
  _hover: {
    backgroundColor: 'secondary.200'
  },
  _active: {
    backgroundColor: 'secondary.100'
  }
});

const tertiary = defineStyle({
  backgroundColor: 'tertiary.500',
  border: '1px solid ##B6B6B6',
  textAlign: 'center',
  borderRadius: '8px',
  color: 'white',
  _hover: {
    backgroundColor: 'tertiary.200'
  },
  _active: {
    backgroundColor: 'tertiary.500'
  }
});

const primary = defineStyle({
  backgroundColor: 'primary.500',
  border: '1px solid ##B6B6B6',
  textAlign: 'center',
  borderRadius: '8px',
  width: '200px',
  color: 'white',
  _hover: {
    backgroundColor: 'primary.200'
  },
  _active: {
    backgroundColor: 'primary.500'
  }
});

const toolbar = defineStyle({
  backgroundColor: '#ffffff',
  _hover: {
    backgroundColor: '#efefef'
  },
  border: '1px solid #dddbdb',
  textAlign: 'center',
  padding: '5px',
  borderRadius: '5px',
  color: 'rgb(50, 49, 48)',
  fontWeight: 'normal',
  fontSize: '14px',
  minWidth: '75px',
  height: '40px'
});

const radioButtonSelected = defineStyle({
  backgroundColor: 'primary.500',
  color: 'white',
  borderRadius: '8px',
  width: ['100px', '200px'],
  _hover: {
    backgroundColor: 'primary.200'
  },
  _active: {
    backgroundColor: 'primary.500'
  }
});

const radioButtonUnselected = defineStyle({
  backgroundColor: 'white',
  color: 'black',
  borderRadius: '8px',
  border: '1px solid',
  borderColor: 'primary.500',
  width: ['100px', '200px'],
  _hover: {
    backgroundColor: 'primary.50',
    borderColor: 'primary.50'
  },
  _active: {
    backgroundColor: 'primary.500'
  }
});

export const buttonTheme = defineStyleConfig({
  variants: { primary, secondary, tertiary, toolbar, radioButtonSelected, radioButtonUnselected },
  defaultProps: { variant: 'primary' }
});
