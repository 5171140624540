import { selectAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(selectAnatomy.keys);

const standardInput = definePartsStyle({
  field: {
    background: 'white',
    borderRadius: '2px',
    border: '1px solid #707070'
  },
  icon: {
    borderRadius: '8px'
  }
});

export const selectTheme = defineMultiStyleConfig({
  variants: {
    standardInput
  },
  defaultProps: {
    variant: 'standardInput'
  }
});
