import { Flex } from "@chakra-ui/react";
import { UseFormReturn } from "react-hook-form";
import { Lead, LotSizeUnit } from "../../leads/models/lead.ts";
import { createFieldHelper, StandardFormInput } from "@cksoftware/react-base";
import { ControllerRoutes } from "../../../constants/controllerRoutes.ts";
import { booleanMargin, formGap } from "../LeadCapture.tsx";

type Props = {
  formMethods: UseFormReturn<Lead>;
};

export const Lot = (props: Props) => {
  const fieldHelper = createFieldHelper<Lead>(props.formMethods);
  const fields = [
    fieldHelper
      .numericInput("LotSize")
      .withLabel("Lot size")
      .isNumeric()
      .withValidation({ required: {} }),

    fieldHelper
      .selectInput("LotSizeUnit", {
        items: [
          { Name: "Square Feet", Value: LotSizeUnit.SquareFeet },
          { Name: "Square Meters", Value: LotSizeUnit.SquareMeters },
        ],
      })
      .isNumeric()
      .withValidation({ required: {} }),

    fieldHelper
      .numericInput("LotPrice")
      .withLabel("Lot price")
      .isNumeric()
      .withValidation({ required: {} }),

    fieldHelper
      .textInput("YardSetbacks")
      .withLabel("Yard setbacks")
      .withLabel("If known, what are the front and back yard setbacks? ")
      .withHelpText("Check your local zoning guidelines"),

    fieldHelper
      .textInput("SideSetbacks")
      .withLabel("Side setbacks")
      .withLabel("If known, what are the side yard setbacks? ")
      .withHelpText("Check your local zoning guidelines"),

    fieldHelper
      .selectInputQueryItems("HydrantDistance", {
        searchUrl: ControllerRoutes.selectOptions.enum("HydrantDistance"),
      })
      .withLabel("How far is the nearest fire hydrant?")
      .isNumeric()
      .withValidation({ required: {} }),

    fieldHelper
      .selectInputQueryItems("PowerLineLocation", {
        searchUrl: ControllerRoutes.selectOptions.enum("PowerLineLocation"),
      })
      .isNumeric()
      .withLabel("Currently, is it overhead or underground power?")
      .withValidation({ required: {} }),

    fieldHelper
      .booleanInput("HasPowerTransformer")
      .withLabel("Is there a power transformer on the property?")
      .withContainerProps({
        controlStyleProps: { margin: booleanMargin },
      })

      .withValidation({ required: {} }),

    fieldHelper
      .booleanInput("TransitOriented")
      .withContainerProps({
        controlStyleProps: { margin: booleanMargin },
      })
      .withLabel("Is it in a transit oriented area that has parking easements?")
      .withValidation({ required: {} }),
    fieldHelper
      .booleanInput("TreeProblems")
      .withContainerProps({
        controlStyleProps: { margin: booleanMargin },
      })
      .withLabel(
        "Are there tree problem potentials with boulevard trees or trees on the property?",
      )
      .withValidation({ required: {} }),

    fieldHelper
      .booleanInput("EnvironmentalIssues")
      .withLabel("Known Environmental Issues")
      .withContainerProps({
        controlStyleProps: { margin: booleanMargin },
      })
      .withValidation({ required: {} }),
  ];
  return (
    <Flex flexDir={"column"} gap={formGap}>
      {fields.map((val, key) => {
        return <StandardFormInput inputDef={val} key={key} />;
      })}
    </Flex>
  );
};
