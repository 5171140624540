import {
  RouteAuthenticationRequirement,
  RouteItem,
} from "@cksoftware/react-base";
import { NarrowLayout } from "../components/layout/narrowLayout";
import { ApplicationRoutes } from "../constants/appRoutes.ts";

export const LeadRoutes: RouteItem = {
  text: "My Deals",
  authenticationRequirement: RouteAuthenticationRequirement.Authenticated,
  allowedRoles: [],
  path: ApplicationRoutes.mySubmissions.mySubmissions,
  layout: <NarrowLayout />,
  async lazy() {
    const component = await import("../features/leads/ManageLeads");
    return { Component: component.ManageLeads };
  },
};
