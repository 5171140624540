export const ApplicationRoutes = {
  account: {
    myProfile: "/my-profile",
    login: "/login",
    logout: "/logout",
    resetPassword: "/reset-password",
    confirmEmail: "/confirm-email",
    forgotPassword: "/forgot-password",
  },
  admin: {
    users: "/admin/users",
  },
  mySubmissions: {
    mySubmissions: "/submissions",
  },
};
