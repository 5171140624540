import { tableAnatomy } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers } from "@chakra-ui/react";

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(tableAnatomy.keys);

const striped = definePartsStyle(() => {
  return {
    tbody: {
      tr: {
        "&:nth-of-type(odd)": {
          td: {
            background: "#d9e8fa",
          },
        },
        "&:nth-of-type(even)": {
          td: {
            background: "white",
          },
        },
      },
    },
  };
});

const fieldArray = definePartsStyle(() => {
  return {
    tbody: {
      tr: {
        td: {
          padding: "5px",
        },
      },
    },
    tfoot: {
      tr: {
        "&:last-of-type": {
          th: { borderBottomWidth: 0 },
        },
      },
    },
  };
});

export const tableTheme = defineMultiStyleConfig({
  variants: { fieldArray, striped },
  defaultProps: { size: "md", variant: "striped" },
});
