import { defineStyle, defineStyleConfig } from '@chakra-ui/react';

const icon = defineStyle({
  fontWeight: 'normal',
  fontSize: '1rem'
});

const standard = defineStyle({
  fontWeight: 'bold'
});

export const formLabelTheme = defineStyleConfig({
  variants: { icon, standard },
  defaultProps: { variant: 'standard' }
});
