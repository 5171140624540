(window as any).global ||= window;
import { createRoot } from 'react-dom/client';

import { App } from './App';
import { getAxios } from './util/axios';

(window as any).getAxios = getAxios;

const container = document.getElementById('root')!;

const root = createRoot(container);
root.render(<App />);
