export class Constants {
  public static authPrefix = 'auth';
}

export const AppRoles = {
  Admin: 'Admin',
  UserAdmin: 'UserAdmin',
  SystemAdmin: 'SystemAdmin',
  Developer: 'Developer'
};

export const AllAccessRoles = [AppRoles.SystemAdmin, AppRoles.Developer];
