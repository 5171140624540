import { RouteAuthenticationRequirement, RouteItem } from '@cksoftware/react-base';
import { NarrowLayout } from '../components/layout/narrowLayout';
import { ApplicationRoutes } from '../constants/appRoutes';

export const AccountManagementItems: RouteItem = {
  path: '/',
  authenticationRequirement: RouteAuthenticationRequirement.Unauthenticated,
  layout: <NarrowLayout />,
  subItems: [
    {
      authenticationRequirement: RouteAuthenticationRequirement.Unauthenticated,
      path: ApplicationRoutes.account.resetPassword,
      async lazy() {
        const component = await import('../features/auth/ResetPassword');
        return { Component: component.ResetPasswordPage };
      }
    },
    {
      authenticationRequirement: RouteAuthenticationRequirement.Unauthenticated,
      path: ApplicationRoutes.account.forgotPassword,
      async lazy() {
        const component = await import('../features/auth/ForgotPassword');
        return { Component: component.ForgotPasswordPage };
      }
    },
    {
      text: 'Logout',
      disableDefaultNavItem: true,
      authenticationRequirement: RouteAuthenticationRequirement.Unauthenticated,
      path: ApplicationRoutes.account.logout,
      async lazy() {
        const component = await import('../features/auth/Logout');
        return { Component: component.Logout };
      }
    }
  ]
};

export const LogoutRouteItem: RouteItem = {
  text: 'Logout',
  disableDefaultNavItem: true,
  authenticationRequirement: RouteAuthenticationRequirement.Authenticated,
  allowedRoles: [],
  path: ApplicationRoutes.account.logout,
  async lazy() {
    const component = await import('../features/auth/Logout');
    return { Component: component.Logout };
  }
};

export const MyAccountRouteItem: RouteItem = {
  text: 'My Account',
  layout: <NarrowLayout />,
  authenticationRequirement: RouteAuthenticationRequirement.Authenticated,
  disableDefaultNavItem: true,
  allowedRoles: [],
  path: ApplicationRoutes.account.myProfile,
  async lazy() {
    const component = await import('../features/auth/myProfile/MyProfile');
    return { Component: component.MyProfile };
  }
};

export const LoginRouteItem: RouteItem = {
  path: ApplicationRoutes.account.login,
  authenticationRequirement: RouteAuthenticationRequirement.Unauthenticated,
  text: 'Login',
  layout: <NarrowLayout />,
  async lazy() {
    const component = await import('../features/auth/Login');
    return { Component: component.LoginPage };
  }
};
