import { Text } from "@chakra-ui/react";

export const Disclaimer = () => {
  return (
    <Text>
      <br />
      This form will equip our team for excellence in deliverable execution,
      deal feasibility and act as a training tool while maximizing your time
      inputs and optimizing your outcomes.
      <br />
      <br />
      This is not a pray and spray tool to use on any lot, be sure to invest
      your time into finding the info out as best as you can from websites,
      phoning people, and talking to the city.
      <br />
      <br />
      Please be sure to be as detailed as possible via city maps links, new
      articles, known property owners, investments going on the area, etc.
      <br />
      <br />
      If you cannot find the answer to the question after genuinely searching,
      put a Question Mark as the response. Links are great so feel free to use
      them in the last question response.
      <br />
      <br />
      Our goal is to get back to you with it 72 hours with some initial feedback
      or a deal sheet summarizing the actual potential your submission has for
      new development.
      <br />
      <br />
      If you need an update on a file, text us the address to{" "}
      <b>587-416-0054</b>.
    </Text>
  );
};
