import { Flex } from "@chakra-ui/react";
import { UseFormReturn } from "react-hook-form";
import { Lead } from "../../leads/models/lead.ts";
import { createFieldHelper } from "@cksoftware/react-base";
import { emailRegex } from "../../../constants/regex.ts";
import { formGap } from "../LeadCapture.tsx";

type Props = {
  formMethods: UseFormReturn<Lead>;
};

export const AboutYou = (props: Props) => {
  const fieldHelper = createFieldHelper<Lead>(props.formMethods);
  const fields = [
    fieldHelper.textInput("Email").withValidation({
      required: { message: "Email is required" },
      pattern: { value: emailRegex, message: "Invalid email address" },
    }),
    fieldHelper.textInput("FirstName").withValidation({ required: {} }),
    fieldHelper.textInput("LastName").withValidation({ required: {} }),
    fieldHelper
      .textInput("PhoneNumber")
      .withValidation({ required: {} })
      .withInputProps({}),
  ];
  return (
    <Flex flexDir={"column"} gap={formGap}>
      {fieldHelper.renderFieldHelperInput("Email", fields)}
      {fieldHelper.renderFieldHelperInput("FirstName", fields)}
      {fieldHelper.renderFieldHelperInput("LastName", fields)}
      {fieldHelper.renderFieldHelperInput("PhoneNumber", fields)}
    </Flex>
  );
};
