import { Flex, Heading, Text } from "@chakra-ui/react";
import { formGap } from "../LeadCapture.tsx";

export const FormComplete = () => {
  return (
    <Flex flexDir={"column"} gap={formGap}>
      <Heading>Submission Complete</Heading>
      <Text>
        Your submission has been received. Someone from our team will be
        reaching out within 72 hours on next steps.
      </Text>
    </Flex>
  );
};
