/* eslint-disable @typescript-eslint/naming-convention */
declare global {
  interface Window {
    REACT_APP_API_BASE_URL: string | undefined;
  }
}

export const API_URL =
  window.REACT_APP_API_BASE_URL ||
  (import.meta.env.VITE_REACT_APP_API_BASE_URL as string);
