import { Flex } from "@chakra-ui/react";
import { UseFormReturn } from "react-hook-form";
import { Lead } from "../../leads/models/lead.ts";
import { createFieldHelper, StandardFormInput } from "@cksoftware/react-base";
import { booleanMargin, formGap } from "../LeadCapture.tsx";

type Props = {
  formMethods: UseFormReturn<Lead>;
};

export const Finances = (props: Props) => {
  const fieldHelper = createFieldHelper<Lead>(props.formMethods);
  const fields = [
    fieldHelper
      .numberSlider("ConfidenceLevel", { min: 1, max: 5, step: 1 })
      .withInputProps({
        sliderMarks: [
          { value: 1, text: "1 (None)" },
          { value: 2, text: "2" },
          { value: 3, text: "3" },
          { value: 4, text: "4" },
          { value: 5, text: "5 (Max)" },
        ],
      })
      .isNumeric()
      .withContainerProps({ controlStyleProps: { w: "95%" } })
      .withLabel(
        "Given your experience and insights, how confident are you that this deal will make sense",
      )
      .withValidation({ required: {} }),
    fieldHelper
      .selectInput("CashOnHand", {
        items: [
          { Value: "$250,000 - $500,000", Name: "$250,000 - $500,000" },
          { Value: "$500,000 - $750,000", Name: "$500,000 - $750,000" },
          { Value: "$750,000 - $1,000,000", Name: "$750,000- $1,000,000" },
          { Value: "$1,000,000 or more", Name: "$1,000,000 or more" },
        ],
      })
      .withLabel(
        "Because cash flow is key and given our experience, you need about 10% of the total project costs to get to the permit stage." +
          " Knowing that, how much capital do you have on hand to move forward with this?",
      )
      .withContainerProps({
        controlStyleProps: { margin: "8px 0px" },
      })
      .withValidation({ required: {} }),

    fieldHelper
      .booleanInput("CanCloseInCash")
      .withLabel(
        "Do you have the net worth covenant space to take on the requirements for construction financing?",
      )
      .withContainerProps({
        controlStyleProps: { margin: booleanMargin },
      })
      .withValidation({ required: {} }),

    fieldHelper
      .numericInput("PredictedRentForUnit")
      .withLabel("Predicted rent for a single bedroom unit.")
      .isNumeric()
      .withValidation({ required: {} }),

    fieldHelper
      .textArea("OtherInformation")
      .withLabel(
        "Provide a 3-4 sentence summary to help us understand why you believe this is a property worth investigating and investing time into for preliminary due diligence. " +
          "Then. with point form notes, please note down some key things you want us to know.",
      )
      .withValidation({ required: {} }),
  ];
  return (
    <Flex flexDir={"column"} gap={formGap}>
      {fields.map((val, key) => {
        return <StandardFormInput inputDef={val} key={key} />;
      })}
    </Flex>
  );
};
