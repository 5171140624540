import { useMutation } from "@tanstack/react-query";
import { Lead } from "../../leads/models/lead.ts";
import { getAxios } from "../../../util/axios.ts";

export function useLeadCaptureMutation() {
  return useMutation({
    mutationFn: async (model: Lead) =>
      await getAxios().post("/leads/onboarding", model),
  });
}
