const roots = {
  selectOption: "/selectoption",
};

export const ControllerRoutes = {
  Util: {
    UsersInRole: "/UsersInRole",
  },
  selectOptions: {
    enum: (typeName: string, includeZero: boolean = false) =>
      `${roots.selectOption}/Enum/${typeName}?includeZero=${includeZero}`,
  },
  Leads: "/leads",
};
