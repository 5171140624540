import { Flex } from "@chakra-ui/react";
import { UseFormReturn } from "react-hook-form";
import { Lead } from "../../leads/models/lead.ts";
import { createFieldHelper, StandardFormInput } from "@cksoftware/react-base";
import { formGap } from "../LeadCapture.tsx";

type Props = {
  formMethods: UseFormReturn<Lead>;
};
const urlRegex =
  /^(https?:\/\/)?(www\.)?([a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)+)(:[0-9]{1,5})?(\/[a-zA-Z0-9-.?,'\/+&%$#=~]*)?$/;

export const Zoning = (props: Props) => {
  const fieldHelper = createFieldHelper<Lead>(props.formMethods);
  const fields = [
    fieldHelper
      .textInput("CurrentZoning")
      .withLabel("Current zoning")
      .withValidation({ required: {} }),
    fieldHelper
      .textInput("CityZoningChanges")
      .withLabel("OCP/MNP (City approved zoning changes)")
      .withValidation({ required: {} }),
    fieldHelper
      .textInput("TraditionalRezonePotential")
      .withLabel("Traditional ReZone Potential")
      .withValidation({ required: {} }),
    fieldHelper
      .textInput("RezoneNotes")
      .withLabel("Website link to local zoning guidelines")
      .withValidation({
        pattern: {
          value: urlRegex,
          message: "Value must be a valid website link.",
        },
      }),
  ];
  return (
    <Flex flexDir={"column"} gap={formGap}>
      {fields.map((val, key) => {
        return <StandardFormInput inputDef={val} key={key} />;
      })}
    </Flex>
  );
};
