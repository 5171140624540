import { defineStyle, defineStyleConfig } from '@chakra-ui/react';

const header = defineStyle({
  fontSize: '24px',
  paddingTop: '5px',
  paddingBottom: '5px'
});

const modalHeader = defineStyle({
  fontSize: '24px',
  paddingTop: '5px',
  paddingBottom: '0px'
});

const fieldArray = defineStyle({
  fontSize: '16px'
});

export const headerTheme = defineStyleConfig({
  variants: {
    header,
    modalHeader,
    fieldArray
  },
  defaultProps: {
    variant: 'header'
  }
});
