import { EntityModel } from "@cksoftware/react-base";

export type Lead = EntityModel & {
  //About You
  FirstName: string;
  LastName: string;
  Email: string;
  PhoneNumber: string;
  UnderContract: boolean;

  //Property Location
  AddressModel: AddressModel;
  LandOwnerInformation: string;
  Neighborhood: string;

  //Zoning
  CurrentZoning: string;
  CityZoningChanges: string;
  TraditionalRezonePotential: string;
  RezoneNotes: string;

  //Lot
  LotSize: number;
  LotSizeUnit: LotSizeUnit;
  YardSetbacks: string;
  SideSetbacks: string;
  LotPrice: number;
  TreeProblems: boolean;
  PowerLineLocation: PowerLineLocation;
  HasPowerTransformer: boolean;
  TransitOriented: boolean;
  EnvironmentalIssues: boolean;
  HydrantDistance: HydrantDistance;

  //Finances
  ConfidenceLevel: number;
  CashOnHand: string;
  CanCloseInCash: boolean;
  HasCovenantSpace: boolean;
  PredictedRentForUnit: number;
  OtherInformation: string;
};

export enum LotSizeUnit {
  None = 0,
  SquareFeet = 1,
  SquareMeters = 2,
}

export enum HydrantDistance {
  None = 0,
  FiftyMetersOrLess = 1,
  OneHundredMeters = 2,
  TwoHundredMeters = 3,
  ThreeHundredMeters = 4,
}

export enum PowerLineLocation {
  None = 0,
  Overhead = 1,
  Underground = 2,
}

export enum Status {
  Submitted,
  UnderReview,
  ActionRequired,
  Approved,
  Rejected,
}

export type AddressModel = {
  AddressLineOne: string;
  AddressLineTwo?: string;
  UnitNumber?: string;
  AddressSearchString?: string;
  City: string;
  PostalCode: string;
  Province: string;
};
