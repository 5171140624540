import { extendTheme } from "@chakra-ui/react";
import { ChakraThemeConfig } from "@cksoftware/react-base";
import { alertTheme } from "./components/alert";
import { buttonTheme } from "./components/button";
import { cardTheme } from "./components/card";
import { drawerTheme } from "./components/drawer";
import { formTheme } from "./components/form";
import { formLabelTheme } from "./components/formLabel";
import { headerTheme } from "./components/heading";
import { inputTheme } from "./components/input";
import { modalTheme } from "./components/modal";
import { numberInputTheme } from "./components/numberInput";
import { selectTheme } from "./components/select";
import { switchTheme } from "./components/switch";
import { tabsTheme } from "./components/tab";
import { tableTheme } from "./components/table";
import { textTheme } from "./components/text";
import { StepsTheme as Steps } from "chakra-ui-steps";

const themeConfig: ChakraThemeConfig = {
  paddings: {
    tableHeightBottomPadding: 190,
  },
};

export const ChakraTheme = extendTheme({
  ...themeConfig,
  components: {
    Input: inputTheme,
    Button: buttonTheme,
    Select: selectTheme,
    NumberInput: numberInputTheme,
    Form: formTheme,
    Text: textTheme,
    Modal: modalTheme,
    Heading: headerTheme,
    Tabs: tabsTheme,
    Card: cardTheme,
    Table: tableTheme,
    Switch: switchTheme,
    Drawer: drawerTheme,
    Alert: alertTheme,
    FormLabel: formLabelTheme,
    Steps,
  },

  styles: {
    global: {
      body: {
        background: "#faf9f67f",
      },
    },
  },
  colors: {
    primary: {
      "50": "#ffe5e3",
      "100": "#ffbbb9",
      "200": "#ff8f8d",
      "300": "#ff6461",
      "400": "#ff3a37",
      "500": "#bf2e1a",
      "600": "#993b2a",
      "700": "#732d1f",
      "800": "#4d1e14",
      "900": "#26100a",
    },
    secondary: {
      "50": "#e3ffed",
      "100": "#b9ffdb",
      "200": "#8dffc9",
      "300": "#61ffb7",
      "400": "#37ffa5",
      "500": "#1abf6e",
      "600": "#29b36f",
      "700": "#1f8a55",
      "800": "#14603b",
      "900": "#0a3721",
    },
    tertiary: {
      "50": "#e3f2ff",
      "100": "#b9e0ff",
      "200": "#8dceff",
      "300": "#61bbff",
      "400": "#37a9ff",
      "500": "#007bff",
      "600": "#006be3",
      "700": "#0056b2",
      "800": "#003e81",
      "900": "#002651",
    },
  },
});
