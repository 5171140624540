import {
  CardPageContainer,
  FormContainer,
  useHookForm,
  useStandardToast,
} from "@cksoftware/react-base";
import { Box, Button, Flex } from "@chakra-ui/react";
import { Lead } from "../leads/models/lead.ts";
import { Step, Steps, useSteps } from "chakra-ui-steps";
import { Disclaimer } from "./steps/disclaimer.tsx";
import { AboutYou } from "./steps/aboutYou.tsx";
import { PropertyLocation } from "./steps/propertyLocation.tsx";
import { Zoning } from "./steps/zoning.tsx";
import { Lot } from "./steps/lot.tsx";
import { Finances } from "./steps/finances.tsx";
import { useLeadCaptureMutation } from "./api/useLeadCaptureMutation.tsx";
import { FormComplete } from "./steps/formComplete.tsx";

export const formGap = "12px";
export const booleanMargin = "6px 0px";
export const LeadCapture = () => {
  const { formMethods } = useHookForm<Lead>();
  const submitMutation = useLeadCaptureMutation();
  const toast = useStandardToast();

  const steps = [
    { label: "Disclaimer", content: <Disclaimer /> },
    { label: "About You", content: <AboutYou formMethods={formMethods} /> },
    {
      label: "Property Location",
      content: <PropertyLocation formMethods={formMethods} />,
    },
    { label: "Zoning", content: <Zoning formMethods={formMethods} /> },
    { label: "Lot", content: <Lot formMethods={formMethods} /> },
    { label: "Finances", content: <Finances formMethods={formMethods} /> },
    { label: "Finished", content: <FormComplete /> },
  ];
  const { nextStep, prevStep, activeStep } = useSteps({
    initialStep: 0,
  });

  const onNextStep = () => {
    formMethods.trigger().then((isValid) => {
      if (isValid) {
        if (activeStep === steps.length - 2) {
          submitMutation.mutate(formMethods.getValues());
          toast.successToast(
            "Request submitted successfully",
            "Your information has been submitted successfully.",
          );
          nextStep();
        } else {
          nextStep();
        }
      }
    });
  };

  return (
    <FormContainer
      methods={formMethods}
      hideControls={true}
      hideDebugOnLocal={true}
    >
      <CardPageContainer
        header={"Elite Deal Analysis"}
        footerRender={
          <>
            {activeStep !== steps.length - 1 && (
              <Flex width="100%" justify="right">
                <Button
                  isDisabled={activeStep === 0}
                  mr={4}
                  onClick={prevStep}
                  variant="ghost"
                >
                  Prev
                </Button>
                {activeStep === steps.length - 1 ? null : (
                  <Button type={"submit"} onClick={onNextStep}>
                    {activeStep === steps.length - 2 ? "Submit" : "Next"}
                  </Button>
                )}
              </Flex>
            )}
          </>
        }
      >
        <Box paddingTop={"16px"}>
          <Steps
            variant={"simple"}
            colorScheme="primary"
            activeStep={activeStep}
            orientation={"horizontal"}
          >
            {steps.map((step, index) => (
              <Step key={index} label={step.label}>
                <Box w={"95%"} margin={"auto"} marginTop={"16px"}>
                  {step.content || <Box>no content</Box>}
                </Box>
              </Step>
            ))}
          </Steps>
        </Box>
      </CardPageContainer>
    </FormContainer>
  );
};
