import { alertAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers, defineStyle } from '@chakra-ui/react';

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(alertAnatomy.keys);

const xs = defineStyle({
  fontSize: 'xs'
});

const xsContainer = defineStyle({
  padding: '2px'
});

const sizes = {
  xs: definePartsStyle({ title: xs, description: xs, container: xsContainer })
};

export const alertTheme = defineMultiStyleConfig({ sizes });
